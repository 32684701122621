import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslations } from "@bookingcom/lingojs-react";
import type { PressHandler } from "@bookingcom/bui-react/components/Actionable/Actionable.types";
import { useViewport, Button } from "@bookingcom/bui-react";
import { trackEvent } from "@btransport/rides-tracking-publisher";
import { getOrchestrator } from "@bookingcom/web-page-orchestrator";
import { TaxiRoutePlanner } from "../TaxiRoutePlanner";
import { TaxiLocationManager } from "../TaxiLocationManager";
import { TaxiDateTimePickerFormElement } from "../TaxiDateTimePickerFormElement";
import { TaxiPassengers } from "../TaxiPassengers";
import { ReturnToggle } from "../ReturnToggle";
import { TaxiErrorContainer } from "../TaxiErrorContainer";
import { useSearchForm } from "../../hooks/useSearchForm";
import { submitForm, toggleRoutePlanner, setSelectedAirport } from "../../actions/searchForm";
import { AirportDropdown } from "../AirportDropdown";
import { TEST_IDS } from "./SearchForm.constants";
import styles from "./TaxiSearchForm.module.css";
import { SearchType } from "../../types";

interface SearchFormProps extends React.Attributes {
	redirectUrl: string;
	type?: SearchType;
}

const getSearchFormTypeModifier = (type: SearchType): string =>
	type === undefined || type === "RETURN_SEARCH"
		? "taxi-search-form--return-search-type"
		: "taxi-search-form--single-search-type";

export const SearchForm: React.FC<SearchFormProps> = (props) => {
	const searchFormContainer = useRef<HTMLDivElement>(null);
	const { state, dispatch } = useSearchForm();
	const {
		airports,
		dateRangeEnd,
		dateRangeStart,
		dropoff,
		discount,
		hidePassengerField,
		isReturn,
		maxPassengers,
		pickup,
		preferredIata,
		enableLandingPageVariant,
		showRoutePlanner,
	} = state;

	const [isLoading, setIsLoading] = useState(true);

	const { isSmall, isMedium, isLarge } = useViewport();
	const { translate: t } = useTranslations();

	const orchestrator = getOrchestrator();

	const isFreeTaxi = discount === "FREE_TAXI";
	const isMultiIATA = !!(airports?.length > 1);
	const isReturnSearchType = props.type === undefined || props.type !== "SINGLE_SEARCH";

	const handleSubmit = (e: React.MouseEvent): void => {
		e.preventDefault();

		trackEvent({
			category: "SearchComponent",
			action: "Click",
			label: `Search ${isReturn ? "Return" : "Single"}`,
			customDimensions: window.GACustomDimensions || [],
		});

		if (isReturn) {
			orchestrator?.runCommand("TRACK_GOAL", {
				experiment: "searchResultsReturns",
				goal: "addReturnSearchForm",
			});
		}

		dispatch(submitForm(props.redirectUrl));
	};

	const onClose = (): void => {
		dispatch(toggleRoutePlanner());
	};

	useEffect(() => {
		if (!isMultiIATA && airports) {
			dispatch(
				setSelectedAirport({
					iata: airports[0]?.data?.iataCode as string,
					establishment: airports[0]?.data?.name as string,
				}),
			);
		}
	}, [airports, dispatch, isMultiIATA]);

	useEffect(() => {
		setIsLoading(false);

		if (isLarge && showRoutePlanner) {
			dispatch(toggleRoutePlanner());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, isLarge, isMedium, isSmall]);

	useEffect(() => {
		if (showRoutePlanner && pickup.selected && dropoff.selected) {
			dispatch(toggleRoutePlanner());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pickup.selected, dropoff.selected, dispatch]);

	useEffect(() => {
		const body = document.querySelector("body");
		const style = body?.getAttribute("style") || "";

		if (showRoutePlanner) {
			body?.setAttribute("style", `overflow-y: hidden;${style}`);
			return;
		}

		body?.setAttribute("style", style.replace("overflow-y: hidden;", ""));
	}, [showRoutePlanner]);

	const dateTimePickerClassName = useMemo(
		() =>
			[
				styles[`taxi-search-form__container-item`],
				styles[`taxi-search-form__container-date-time${hidePassengerField ? "--full-width" : ""}`],
			].join(" "),
		[hidePassengerField],
	);

	const searchFormClasses = useMemo(
		() =>
			[
				discount === "FREE_TAXI"
					? airports?.length > 1
						? styles["taxi-search-form__container_free_taxi_multi_iata"]
						: styles["taxi-search-form__container_free_taxi"]
					: styles["taxi-search-form__container"],
				hidePassengerField ? styles["taxi-search-form__container--hidden-passenger"] : "",
			]
				.join(" ")
				.trim(),
		[airports?.length, discount, hidePassengerField],
	);

	const searchFormTypeModifier = getSearchFormTypeModifier(props.type);
	const searchFormTypeClassName = `${styles[`${searchFormTypeModifier}${hidePassengerField ? "--hidden-passenger" : ""}`]}`;

	return (
		<div ref={searchFormContainer}>
			{showRoutePlanner ? (
				<div
					className={[
						styles["taxi-search-form"],
						searchFormTypeClassName,
						!isLoading && isMedium ? styles["taxi-search-form--medium-size"] : "",
						!isLoading && isSmall ? styles["taxi-search-form--small-size"] : "",
					]
						.join(" ")
						.trim()}
				>
					<TaxiRoutePlanner
						onClose={onClose}
						title={t("gt_mig_rides_web_search_form_route-panner_header")}
						closeButtonAriaLabel={t("gt_mig_rides_web_search_form_search_close-route-planner")}
					/>
				</div>
			) : (
				<form
					className={[
						styles["taxi-search-form"],
						searchFormTypeClassName,
						!isLoading && isMedium ? styles["taxi-search-form--medium-size"] : "",
						!isLoading && isSmall ? styles["taxi-search-form--small-size"] : "",
					]
						.join(" ")
						.trim()}
				>
					{isReturnSearchType && discount !== "FREE_TAXI" && <ReturnToggle isLandingPage={enableLandingPageVariant} />}
					<div data-testid={TEST_IDS.searchFormContainer} className={searchFormClasses}>
						{!isFreeTaxi && (
							<div className={styles["taxi-search-form__container-item"]}>
								{!isLarge && <TaxiErrorContainer />}
								<TaxiLocationManager />
							</div>
						)}
						{isFreeTaxi && isMultiIATA && airports && (
							<div className={styles["taxi-search-form__container-item"]}>
								<AirportDropdown airports={airports} preferredIata={preferredIata} />
							</div>
						)}
						<div className={dateTimePickerClassName}>
							<TaxiDateTimePickerFormElement
								name="oneway"
								dateRangeStart={dateRangeStart}
								dateRangeEnd={dateRangeEnd}
							/>
						</div>
						{isReturnSearchType && !isFreeTaxi && (
							<div className={dateTimePickerClassName}>
								<TaxiDateTimePickerFormElement name="return" />
							</div>
						)}
						{!hidePassengerField && (
							<div
								className={[
									styles["taxi-search-form__container-item"],
									styles["taxi-search-form__container-taxi-passengers"],
								].join(" ")}
							>
								<TaxiPassengers maxPassengers={maxPassengers} />
							</div>
						)}
						<div
							className={[
								styles["taxi-search-form__container-item"],
								styles["taxi-search-form__container-submit-button"],
							].join(" ")}
						>
							<Button
								text={
									discount === "FREE_TAXI"
										? t("gt_mig_rides_search-form_update-button")
										: t("gt_mig_rides_web_search_form_search_search")
								}
								type="submit"
								size="large"
								attributes={{
									"data-testid": TEST_IDS.searchFormSubmitButton,
								}}
								className={styles["taxi-search-form__container-item__button"]}
								onClick={handleSubmit as PressHandler}
								wide
							/>
						</div>
					</div>
				</form>
			)}
		</div>
	);
};
