type TaxiSearchboxContext = {
	attributionUrlParams: {
		aid?: string;
		label?: string;
	};
};

/**
 * Extracts PPC tracking parameters from session storage
 * @returns {Object} Object containing aid and label if available
 */
export const getTaxiSearchboxContext = (): TaxiSearchboxContext["attributionUrlParams"] | undefined => {
	const taxiSearchboxContext = sessionStorage.getItem("TAXI_SEARCHBOX_CONTEXT");

	if (taxiSearchboxContext) {
		try {
			const parsedContext: TaxiSearchboxContext = JSON.parse(taxiSearchboxContext);
			const { attributionUrlParams } = parsedContext;

			return {
				aid: attributionUrlParams?.aid,
				label: attributionUrlParams?.label,
			};
		} catch {
			// Silent error handling, no logging
		}
	}

	return undefined;
};
